
import { defineComponent, ref, watch } from 'vue';
import CallDrawer from '../../components/CallDrawer/CallDrawer.vue';
import CallToolbar from '../../components/CallToolbar/CallToolbar.vue';
import { setUserId, setUserType, setIdentifyingInfo, clinician } from '../../store/user';
import { callActive, connected, awaitingApproval, setSessionConnected } from '../../store/session';
import { attendeeCount } from '../../store/attendees';
import CallActions from '../../utils/CallActions';
import { setMeetingId } from '../../store/env';
import HomeLayout from '../../components/Layout/HomeLayout.vue';
import Salutation from '../../components/Layout/components/Salutation.vue';

export default defineComponent({
  name: 'Home',
  props: {
    meetingId: {
      type: String,
      required: true,
    },
    tokenInfo: {
      type: String,
      required: true,
    },
  },
  components: {
    CallDrawer,
    CallToolbar,
    HomeLayout,
    Salutation,
  },
  setup(props) {
    const sessionId = ref(null);
    const token = ref(null);
    const callEnded = ref(false);
    setMeetingId(props.meetingId as string);
    setIdentifyingInfo(props.tokenInfo as string);

    watch([connected], (newVal, oldVal) => {
      if (!newVal[0] && oldVal[0]) {
        callEnded.value = true;
      }
    });

    const callCreds = {
      meeting_id: props.meetingId,
    };

    CallActions.joinCall(callCreds).then((meeting) => {
      token.value = meeting.attributes.session_token;
      sessionId.value = meeting.relationships.session.video_session_id;
      setUserId(meeting.attributes.user_id);
      setUserType(meeting.attributes.role);
    });

    const returnToLobby = () => {
      window.location.reload();
    };

    return {
      sessionId,
      token,
      callActive,
      connected,
      awaitingApproval,
      attendeeCount,
      callEnded,
      clinician,
      returnToLobby,
    };
  },
});
